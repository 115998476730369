<template>
        <div>
        <div v-if="!auth.authenticated && authstate.loading === false" class="container"> 
          <div> <p>Login with your Discord Credentials to view the "HeatMapFX Open Order Book"</p> </div>
          <div> <p>If you are a Paid Member, login for basic access</p> </div>
          <div class="btn btn-dark btn-lg active" v-on:click="auth.loginWithRedirect()" :disabled="auth.authenticated">Login</div>
          <hr>
        <!-- <div> Auth Level? : {{authLevel}} </div> -->
        <div > <p>If you are NOT a Paid Member, start your trial here:</p></div>
        <div>
          <a href="https://launchpass.com/heatmapfx/paid-members" class="btn btn-secondary btn-lg active" role="button">
          Start your HeatMapFx Paid Trial Here</a>
        </div>
        <hr>
 </div>
        <div v-if="auth.authenticated && authLevel === 0">
        <!-- Stop Loss Hunter App -->
        <!-- {{info}} -->
        <!-- <div> Beta Member? : {{is_betatester}} </div> -->
        <!-- <div> ST Member? : {{is_specialtester}} </div> -->
        <!-- <div> Basic Member? : {{is_paid}} </div> -->
        <!-- <div> Premium Member? : {{is_prem}} </div> -->
        <!-- <div> tboneMember? : {{is_tbone}} </div> -->
        <!-- <div> Auth Level? : {{authLevel}} </div> -->
        <div > This section is for paid members only.  To access the paid data please sign up here:</div>
        <div class="row">
          <a href="https://launchpass.com/heatmapfx/paid-members" class="btn btn-secondary btn-lg active" role="button">
          Start your HeatMapFx Paid Trial Here</a>
        </div>
        </div>
        <div v-if="auth.authenticated && authLevel === 1 || authLevel === 2 || authLevel === 3 || authLevel === 4" class="container-fluid"> 
          <!-- <div> Auth Level? : {{authLevel}} </div> -->
        <!-- <div> Auth Level? : {{authLevel}} </div> -->
        <!-- <div> this isinBeta : {{isinbeta}} </div> -->
        <!-- <div> this isinspecial : {{isinspecial}} </div> -->
        <!-- <div> this isinpaid : {{isinpaid}} </div> -->
        <!-- <div> this isinprem : {{isinprem}} </div> -->
        <!-- <div> this isinTBone : {{isinTbone}} </div> -->
          <div class="row datestyle">{{browserDate}}</div>
          <div class="row selectName"> Select a Pair </div>
         <div class="row selectBoxPad">
            <select class="select" v-model="pairx">
               <option v-for="item in usdpairs" v-bind:key="item.pair" :value="item.pair">{{item.pair}}</option>
              </select>                      
             </div>
             <div v-if="filteredoob && filteredoob.length" class="row">
            <div  class="col">
              <table class="table table-striped table-hover caption-top" v-for="(name) in filteredoob" v-bind:key="name" >
               <caption v-if="filteredoob">HeatMapFX Open Order Book | updated:{{this.oobtime}}</caption>
               <thead> 
                 <tr>
                   <td> Pair </td>
                   <!-- <td> Current Price </td> -->
                   <td> Order Price </td>
                   <td> Percent Orders </td>
                   <td> Sums (L+S)</td>
                   <td> Dir </td>
                 </tr>
               </thead>
               <tbody v-for="(item) in name" v-bind:key="item"> 
                  <tr >
                   <td rowspan="0" style="vertical-align : middle;">{{item[0].B[0].pair}}</td>
                   <!-- <td>{{item[1].S[0].price}}</td> -->
                   <td :class="{sell: item[1].S[0].bucketsPrice}">{{item[1].S[0].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[1].S[0].shortCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightbs: item[1].S[0].longCountPercent > item[1].S[0].shortCountPercent}">{{item[1].S[0].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[1].S[0].dir}}</td>                
                 </tr>
               <tr >
                   <!-- <td>{{item[0].B[1].pair}}</td> -->
                   <!-- <td>{{item[1].S[1].price}}</td> -->
                   <td :class="{sell: item[1].S[1].bucketsPrice}">{{item[1].S[1].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[1].S[1].shortCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightbs: item[1].S[1].longCountPercent > item[1].S[1].shortCountPercent}">{{item[1].S[1].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[1].S[1].dir}}</td>                
                 </tr>
                    <tr >
                   <!-- <td>{{item[0].B[2].pair}}</td> -->
                   <!-- <td>{{item[1].S[2].price}}</td> -->
                   <td :class="{sell: item[1].S[2].bucketsPrice}">{{item[1].S[2].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[1].S[2].shortCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightbs: item[1].S[2].longCountPercent > item[1].S[2].shortCountPercent}">{{item[1].S[2].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[1].S[2].dir}}</td> 
                    </tr >
                    <tr>
                   <!-- <td>{{item[0].B[3].pair}}</td> -->
                   <!-- <td>{{item[1].S[3].price}}</td> -->
                   <td :class="{sell: item[1].S[3].bucketsPrice}">{{item[1].S[3].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[1].S[3].shortCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightbs: item[1].S[3].longCountPercent > item[1].S[3].shortCountPercent}">{{item[1].S[3].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[1].S[3].dir}}</td>  
                    </tr >
                    <tr>
                   <!-- <td>{{item[0].B[4].pair}}</td> -->
                   <!-- <td>{{item[1].S[4].price}}</td> -->
                   <td :class="{sell: item[1].S[4].bucketsPrice}">{{item[1].S[4].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[1].S[4].shortCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightbs: item[1].S[4].longCountPercent > item[1].S[4].shortCountPercent}">{{item[1].S[4].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[1].S[4].dir}}</td> 
                    </tr>
                 <tr class="currentPrice"> 
                   <td> {{item[0].B[0].price.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td> Current Price </td>
                  </tr>
                 <tr >
                   <!-- <td>{{item[0].B[0].price}}</td> -->
                   <td :class="{buy: item[0].B[0].bucketsPrice}">{{item[0].B[0].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[0].B[0].longCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightss: item[0].B[0].longCountPercent < item[0].B[0].shortCountPercent}">{{item[0].B[0].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[0].B[0].dir}}</td>                
                 </tr>
               <tr >
                   <!-- <td>{{item[0].B[1].pair}}</td> -->
                   <!-- <td>{{item[0].B[1].price}}</td> -->
                   <td :class="{buy: item[0].B[1].bucketsPrice}">{{item[0].B[1].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[0].B[1].longCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightss: item[0].B[1].longCountPercent < item[0].B[1].shortCountPercent}">{{item[0].B[1].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[0].B[1].dir}}</td>                
                 </tr>
                    <tr >
                   <!-- <td>{{item[0].B[2].pair}}</td> -->
                   <!-- <td>{{item[0].B[2].price}}</td> -->
                   <td :class="{buy: item[0].B[2].bucketsPrice}">{{item[0].B[2].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[0].B[2].longCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightss: item[0].B[2].longCountPercent < item[0].B[2].shortCountPercent}">{{item[0].B[2].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td> 
                    <td>{{item[0].B[2].dir}}</td> 
                    </tr >
                    <tr>
                   <!-- <td>{{item[0].B[3].pair}}</td> -->
                   <!-- <td>{{item[0].B[3].price}}</td> -->
                   <td :class="{buy: item[0].B[3].bucketsPrice}">{{item[0].B[3].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[0].B[3].longCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightss: item[0].B[3].longCountPercent < item[0].B[3].shortCountPercent}">{{item[0].B[3].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td> 
                    <td>{{item[0].B[3].dir}}</td> 
                    </tr >
                    <tr>
                   <!-- <td>{{item[0].B[4].pair}}</td> -->
                   <!-- <td>{{item[0].B[4].price}}</td> -->
                   <td :class="{buy: item[0].B[4].bucketsPrice}">{{item[0].B[4].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[0].B[4].longCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightss: item[0].B[4].longCountPercent < item[0].B[4].shortCountPercent}">{{item[0].B[4].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>  
                    <td>{{item[0].B[4].dir}}</td>                
                 </tr>
               </tbody>
             </table>
             
           </div>
            <div class="col"></div>
        </div>
           <div v-else class="row">
             <div class="col">
             <table class="table table-striped table-hover caption-top" v-for="(name) in ioobBuys" v-bind:key="name" >
               <caption v-if="ioobBuys">HeatMapFX Open Order Book | updated: {{this.oobtime}}</caption>
               <thead> 
                 <tr>
                   <td> Pair </td>
                   <!-- <td> Current Price </td> -->
                   <td> Order Price </td>
                   <td> Percent Orders </td>
                   <td> Sums (L+S)</td>
                   <td> Dir </td>
                 </tr>
               </thead>
               <tbody v-for="(item) in name" v-bind:key="item"> 
                  <tr >
                   <td rowspan="0" style="vertical-align : middle;">{{item[0].B[0].pair}}</td>
                   <!-- <td>{{item[1].S[0].price}}</td> -->
                   <td :class="{sell: item[1].S[0].bucketsPrice}">{{item[1].S[0].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[1].S[0].shortCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightbs: item[1].S[0].longCountPercent > item[1].S[0].shortCountPercent}">{{item[1].S[0].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[1].S[0].dir}}</td>                
                 </tr>
               <tr >
                   <!-- <td>{{item[0].B[1].pair}}</td> -->
                   <!-- <td>{{item[1].S[1].price}}</td> -->
                   <td :class="{sell: item[1].S[1].bucketsPrice}">{{item[1].S[1].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[1].S[1].shortCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightbs: item[1].S[1].longCountPercent > item[1].S[1].shortCountPercent}">{{item[1].S[1].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[1].S[1].dir}}</td>                
                 </tr>
                    <tr >
                   <!-- <td>{{item[0].B[2].pair}}</td> -->
                   <!-- <td>{{item[1].S[2].price}}</td> -->
                   <td :class="{sell: item[1].S[2].bucketsPrice}">{{item[1].S[2].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[1].S[2].shortCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightbs: item[1].S[2].longCountPercent > item[1].S[2].shortCountPercent}">{{item[1].S[2].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[1].S[2].dir}}</td> 
                    </tr >
                    <tr>
                   <!-- <td>{{item[0].B[3].pair}}</td> -->
                   <!-- <td>{{item[1].S[3].price}}</td> -->
                   <td :class="{sell: item[1].S[3].bucketsPrice}">{{item[1].S[3].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[1].S[3].shortCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightbs: item[1].S[3].longCountPercent > item[1].S[3].shortCountPercent}">{{item[1].S[3].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[1].S[3].dir}}</td>  
                    </tr >
                    <tr>
                   <!-- <td>{{item[0].B[4].pair}}</td> -->
                   <!-- <td>{{item[1].S[4].price}}</td> -->
                   <td :class="{sell: item[1].S[4].bucketsPrice}">{{item[1].S[4].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[1].S[4].shortCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightbs: item[1].S[4].longCountPercent > item[1].S[4].shortCountPercent}">{{item[1].S[4].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[1].S[4].dir}}</td> 
                    </tr>
                 <tr class="currentPrice"> 
                   <td> {{item[0].B[0].price.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td> Current Price </td>
                  </tr>
                 <tr >
                   <!-- <td>{{item[0].B[0].price}}</td> -->
                   <td :class="{buy: item[0].B[0].bucketsPrice}">{{item[0].B[0].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[0].B[0].longCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightss: item[0].B[0].longCountPercent < item[0].B[0].shortCountPercent}">{{item[0].B[0].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[0].B[0].dir}}</td>                
                 </tr>
               <tr >
                   <!-- <td>{{item[0].B[1].pair}}</td> -->
                   <!-- <td>{{item[0].B[1].price}}</td> -->
                   <td :class="{buy: item[0].B[1].bucketsPrice}">{{item[0].B[1].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[0].B[1].longCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightss: item[0].B[1].longCountPercent < item[0].B[1].shortCountPercent}">{{item[0].B[1].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>    
                   <td>{{item[0].B[1].dir}}</td>                
                 </tr>
                    <tr >
                   <!-- <td>{{item[0].B[2].pair}}</td> -->
                   <!-- <td>{{item[0].B[2].price}}</td> -->
                   <td :class="{buy: item[0].B[2].bucketsPrice}">{{item[0].B[2].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[0].B[2].longCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightss: item[0].B[2].longCountPercent < item[0].B[2].shortCountPercent}">{{item[0].B[2].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td> 
                    <td>{{item[0].B[2].dir}}</td> 
                    </tr >
                    <tr>
                   <!-- <td>{{item[0].B[3].pair}}</td> -->
                   <!-- <td>{{item[0].B[3].price}}</td> -->
                   <td :class="{buy: item[0].B[3].bucketsPrice}">{{item[0].B[3].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[0].B[3].longCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightss: item[0].B[3].longCountPercent < item[0].B[3].shortCountPercent}">{{item[0].B[3].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td> 
                    <td>{{item[0].B[3].dir}}</td> 
                    </tr >
                    <tr>
                   <!-- <td>{{item[0].B[4].pair}}</td> -->
                   <!-- <td>{{item[0].B[4].price}}</td> -->
                   <td :class="{buy: item[0].B[4].bucketsPrice}">{{item[0].B[4].bucketsPrice.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td>{{item[0].B[4].longCountPercent.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>
                   <td :class="{highlightss: item[0].B[4].longCountPercent < item[0].B[4].shortCountPercent}">{{item[0].B[4].sums.toLocaleString(undefined, { minimumFractionDigits: 4 })}}</td>  
                    <td>{{item[0].B[4].dir}}</td>                
                 </tr>
               </tbody>
             </table>
             
           </div>
            <div class="col"></div>
        </div>
        </div>
        </div>
</template>
<script>
import axios from "axios";
import { AuthenticationState } from 'vue-auth0-plugin';
// import moment from "moment-timezone"
import { format , utcToZonedTime } from "date-fns-tz";
import { filteredoobFunc } from "../functions";
// import _ from 'lodash';
export default ({
  info : "oobBuys",
  inject: ['auth'],
  el: '#app',
  data () {
      return {
      pairx: {},
      usdpairs: [
      {pair: "AUD_USD"},
      {pair: "EUR_USD"},
      {pair: "GBP_USD"},
      {pair: "NZD_USD"},
      {pair: "USD_CAD"},
      {pair: "USD_CHF"},
      {pair: "USD_JPY"},
      {pair: "All 28 Pairs"}
    ],
      ioobBuys: null,
      oobtime: null,
      xoobs: null,
      xpair: "EUR_USD",
      ibetatesters: null,
      ispecialtesters: null,
      itboneUsers: null,
      ipaidMembers: null,
      ipremiumMembers: null,
      authstate: AuthenticationState,
      isinbeta: false,
      isinspecial: false,
      isinTbone: false,
      isinpaid: false,
      isinprem: false
    }
  },
  methods: {
    oobBuys: function(){
      axios.get(this.$apihostname+'/bsOrders')
      //.then(response => {this.ioobBuys = _.groupBy(response.data)})
      .then(response => response.data)
      .then(data => {
        this.ioobBuys = data
        // this.oobtime = moment.utc(data[0].AUD_CAD[0].B[0].time).tz(moment.tz.guess(true)).format('ddd MMMM Do YYYY, h:mm a z')
        this.oobtime = format(utcToZonedTime(data[0].AUD_CAD[0].B[0].time),'EEEE, MMMM dd yyyy, h:mm a z')
        this.xoobs = {'datay':data}
      })
        },
        bdate: function(){
          // this.browserDate = moment.utc().tz(moment.tz.guess(true)).format('dddd MMMM Do YYYY, h:mm a z')
          this.browserDate = format(new Date(),'EEEE, MMMM dd yyyy, h:mm a z')
    },betatesters: function(){
      axios.get(this.$apihostname+'/np/betatesters')
      .then(response => response.data)
      .then(data => {
        this.ibetatesters = data
        this.isinbeta = data.includes(this.auth.user?.sub.slice(15,33))
        })
      },
    specialtesters: function(){
      axios.get(this.$apihostname+'/np/specialtesting')
      .then(response => response.data)
      .then(data => {
        this.ispecialtesters = data
        this.isinspecial = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    paidMembers: function(){    /// Paid Basic Members
      axios.get(this.$apihostname+'/np/paidMembers')
      .then(response => response.data)
      .then(data => {
        this.ipaidMembers = data
        this.isinpaid = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    premiumMembers: function(){    /// Premium Members
      axios.get(this.$apihostname+'/np/premiumMembers')
      .then(response => response.data)
      .then(data => {
        this.ipremiumMembers = data
        this.isinprem = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    tboneUsers: function(){    /// tbone users
      axios.get(this.$apihostname+'/np/tboneUsers')
      .then(response => response.data)
      .then(data => {
        this.itboneUsers = data
        this.isinTbone = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
  },
  created(){
    this.oobBuys()
    this.bdate()
    this.betatesters()
    this.specialtesters()
    this.paidMembers()
    this.premiumMembers()
    this.tboneUsers()
  },
    mounted(){
    const usdPairSelected = localStorage.getItem('usdPairSelected')
    this.pairx = usdPairSelected ? usdPairSelected : {}
    this.interval = setInterval(() => {
        this.oobBuys()
        this.bdate()
        this.betatesters()
        this.specialtesters()
        this.paidMembers()
        this.premiumMembers()
        this.tboneUsers()
    }, 30000 );
 },
 watch: {
    pairx: {
      deep: true,
      handler(pairx) {
        localStorage.setItem('usdPairSelected', pairx)
      }
    }
  },
  computed: {
     pair: function(){
      // console.log(this.pairx)
      return this.pairx
      // return _(this.ioobBuys).groupBy(this.ioobBuys => this.ioobBuys.dir)
    },
    filteredoob : function(){
      return filteredoobFunc(this.xoobs,this.pairx)
    },
    // filteredosa: function(){
    //      return this.officers.filter(id => id.id == this.idsv)
    // },
    // filteredoob: function(){
    //   // console.log(Object.values(this.xoobs))
    //   if(this.xoobs !== null){
    //    var d = this.xoobs.datay
    //    var len = this.xoobs.datay.length
    //    var i = 0, ii = len
    //    var a = []
    //    var t = this.pairx
    //   for(i; i < ii; i++) if(
    //     i < 28
    //     ) {
    //      if(d[i][t] != null){
    //       a.push(d[i])
    //    }
    //   }
    //  }
    //   return a
    // },
       authLevel: function() {
          //  const authp = injectAuth();
            if(
               this.isinspecial === true                
               ){
             return 4
               }
            else if(
               this.isinbeta === true
               ){
              return 3
            }
            else if(
               this.isinprem === true        
               ){
              return 2
            }
            else if(
               this.isinpaid  === true      
               ){
              return 1
            }
            else if(this.isinTbone === true || this.isinpaid === true){
              return 0
            }
            else return 5
        }
  }
})
</script>
<style scoped>
.select {
  width: 300px;
  /* display: inline-block; */
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px rgb(204, 204, 204);
  transition: all .5s ease;
  position: relative;
  font-size: 14px;
  color: #474747;
  height: 100%;
  text-align: left;
  cursor: pointer;
  display: block;
  padding: 5px;
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all .3s ease-in-out;
  float: right;
  line-height: 20px;
  padding-left: 0.5rem;
}
.select:hover {
    box-shadow: 0 0 4px rgb(204, 204, 204)
}
.highlightbs {
  background: #8babd3;
}
.highlightss {
  background: #eeaaaa;
}
.table{
  font-size: 12px;
}
.currentPrice{
  background: orange;
}
.buy{
  background: #8babd3;
}
.sell{
  background: #eeaaaa;
}
.table-hover> tbody> tr:hover{
    background-color:yellow;
}
.datestyle {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: center;
    font-size: 12px;
    display: inline-block;
}
.selectName{
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: center;
    font-size: 12px;
    /* display: inline-block; */
}
.selectBoxPad{
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
router-link{
    color: yellow;
    margin: 0.667em;
    text-decoration: none;
    font-weight: bold;
}
a {
    text-decoration: none;
    color: #4aa9e9;
}
</style>